//FONTS
@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-Light.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-LightIta.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-LightIta.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-Medium.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?z93mgp");
  src: url("../assets/fonts/icomoon.eot?z93mgp#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?z93mgp") format("truetype"),
    url("../assets/fonts/icomoon.woff?z93mgp") format("woff"),
    url("../assets/fonts/icomoon.svg?z93mgp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@import "./variables";
@import "./mixins";
