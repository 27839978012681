$light-font-weight: 200;
@mixin t-body {
  font-weight: normal;
  font-size: 2rem;
  line-height: 3rem;
}
@mixin t-body-sm {
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin t-body-xs {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin sm-paragraph {
  @include t-body-sm;
  width: 100%;
  position: relative;
  float: left;
  color: map-get($colors, "darkgrey");
  a {
    text-decoration: none;
    color: map-get($colors, "mediumblue");
    transition: all 0.25s;
    &:hover,
    &:focus {
      color: map-get($colors, "darkblue");
    }
  }
}

@mixin font-set-up($weight, $size, $color) {
  font-weight: $weight;
  font-family: $type-body;
  color: map-get($colors, $color);
  font-size: $size;
}

@function change-base($input) {
  @return ($input * 16)/10;
}

// $colors: (
//   "white": #fff,
//   "black": #000,
//   "light-blue": #3baaf9,
//   "blue": #3baaf9,
//   "dark-blue": #00305e,
//   "code-blue": #022241,
//   "green": #00aa87,
//   "red": #ff411c,
//   "yellow": #fed843,
//   "light-grey": #d6d6d6,
//   "grey": #b5b5b5,
//   "dark-grey": #6a6a6a,
//   "shadow": #00000029,
//   "shadow-border": #ededed,
//   "orange": #ffa200,
//   "score-good": #00aa87,
//   "score-warning": #ffa200,
//   "score-bad": #fb4d3d,
//   "grey-button": #707070,
//   "lightgrey": #f7f5f2,
//   "mediumgrey": #d0d3d6,
//   "darkgrey": #8b9197,
//   "lightblue": #82aaf9,
//   "mediumblue": #3c78e6,
//   "darkblue": #1a1a42,
//   "mainblue": #33337f,
//   "mediumred": #f07b7b,
// );

@mixin box-shadow-1 {
  box-shadow: 0 0.3rem 0.6rem map-get($colors, "shadow");
  border: 1px solid map-get($colors, "shadow-border");
  border-radius: 1rem;
}

@mixin box-shadow-2 {
  box-shadow: 0 3px 6px map-get($colors, "shadow");
  //border: 1px solid map-get($colors, "shadow-border");
  border-radius: 0.25rem;
}

@mixin blue-link {
  font-size: 14px;
  font-weight: 600;
  color: map-get($colors, "blue");
  text-decoration: underline;
  transition: all 0.25s;
  &:hover,
  &:active {
    color: map-get($colors, "dark-blue");
  }
}

@mixin small-blue-link {
  font-size: 10px;
  font-weight: 600;
  color: map-get($colors, "blue");
  text-decoration: underline;
  &:hover,
  &:active {
    color: map-get($colors, "dark-blue");
  }
}

$sizes: (
  "shape": 285px,
  "sm-shape": 198px,
  "mws": 500px,
  "mwm": 700px,
  "mwl": 1075px,
);

$media: (
  "mw400": "only screen and (min-width:400px)",
  "mw500": "only screen and (min-width:500px)",
  "mw600": "only screen and (min-width:600px)",
  "mw700": "only screen and (min-width:700px)",
  "mw783": "only screen and (min-width:783px)",
  "mw800": "only screen and (min-width:800px)",
  "mw900": "only screen and (min-width:900px)",
  "mw1000": "only screen and (min-width:1000px)",
  "mw1050": "only screen and (min-width:1050px)",
  "mw1100": "only screen and (min-width:1100px)",
  "mw1150": "only screen and (min-width:1150px)",
  "mw1200": "only screen and (min-width:1200px)",
  "mw1250": "only screen and (min-width:1250px)",
  "mw1300": "only screen and (min-width:1300px)",
  "mw1350": "only screen and (min-width:1350px)",
  "mw1400": "only screen and (min-width:1400px)",
  "mw1450": "only screen and (min-width:1450px)",
  "mw1500": "only screen and (min-width:1500px)",
  "mw1550": "only screen and (min-width:1550px)",
  "mw1600": "only screen and (min-width:1600px)",
  "mw1700": "only screen and (min-width:1700px)",
  "mw1800": "only screen and (min-width:1800px)",
  "mw1900": "only screen and (min-width:1900px)",
);

$type-body: "Ginto", Arial, sans-serif;

//FONTS
@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-Light.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-LightIta.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-LightIta.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Ginto";
  src: url("../assets/fonts/GintoNormal-Medium.woff2") format("woff2"),
    url("../assets/fonts/GintoNormal-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
