.compat-chart-mask {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compat-chart-person {
  position: absolute;
  z-index: 100;
  max-width: 200px;
  color: #fff;
  font-size: 26px;
  text-align: center;
}
