.compatibility-wrapper {
  max-width: 1300px;
  margin: 0 auto;
}

.compatibility-section {
  padding: 20px 0;

  &.blue {
    background-color: #1a1a42;
  }
}

.compatibility-grid {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 20px;
}

.matrixRow {
  display: flex;
}

.compatibility-table {
  table-layout: fixed;
  background-color: #fff;

  tr:hover,
  tr.table-row-selected {
    td {
      cursor: pointer;
      outline: 2px solid lightcoral;
    }
  }

  tr:first-child,
  td:last-child,
  th:last-child {
    text-align: center;
  }

  td:first-child,
  th:first-child {
    padding: 5px;
  }

  td {
    border: 1px solid #b6b8b9;
    padding: 5px;
  }
}

.center-align-axis .recharts-yAxis {
  transform: translateX(46%);
}

.compat-lvl-0 {
  // &.0 {
  // stroke: aqua;
  // stroke-width: 4px;
  // }
}
