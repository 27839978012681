.compat-block {
  & + .compat-block {
    margin-top: 8px;
  }
}

.compat-user {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-size: 16px;
  border-left: 4px solid #336de2;
  border-radius: 3px;
  margin-top: 5px;

  &:hover,
  &.selected {
    cursor: pointer;
    background-color: #3c3b5e;
  }
}

.compat-title {
  position: relative;
  cursor: pointer;
  user-select: none;
  text-align: right;
  color: #336de2;
  font-weight: 700;

  &:hover {
    color: #85a8ed;
  }
}

.compat-title-text {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  background-color: #1a1a42;
}

.compat-decor {
  position: absolute;
  top: 54%;
  left: 0;
  width: 100%;
  height: 1px;
  border-top: 2px solid #2d2e53;
}
